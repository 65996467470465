<template>
    <path id="Vector (Stroke)_14" fill-rule="evenodd" clip-rule="evenodd"
        d="M47.5194 976H139.031L173.547 1045.03L139.031 1114.07H47.513L13 1044.89L47.5194 976ZM54.2318 986.871L25.1544 1044.9L54.2383 1103.19H132.312L161.393 1045.03L132.312 986.871H54.2318Z"
        fill="#E8D9AA" />
    <path id="Vector_15" d="M143 79.74L180.37 5H280.114L317.484 79.74L280.114 154.48H180.37L143 79.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_15" fill-rule="evenodd" clip-rule="evenodd"
        d="M176.87 0H282.794L322.664 79.74L282.794 159.48H176.87L137 79.74L176.87 0ZM183.05 10L148.18 79.74L183.05 149.48H276.614L311.484 79.74L276.614 10H183.05Z"
        fill="#D4A168" />
    <path id="Vector_16" d="M143 228.74L180.37 154H280.114L317.484 228.74L280.114 303.48H180.37L143 228.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_16" fill-rule="evenodd" clip-rule="evenodd"
        d="M176.87 149H282.794L322.664 228.74L282.794 308.48H176.87L137 228.74L176.87 149ZM183.05 159L148.18 228.74L183.05 298.48H276.614L311.484 228.74L276.614 159H183.05Z"
        fill="#D4A168" />
</template>
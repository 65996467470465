<template>
    <path id="Vector (Stroke)_34" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 381H413.031L447.547 450.033L413.031 519.065H321.513L287 449.891L321.519 381ZM328.232 391.871L299.154 449.902L328.238 508.194H406.312L435.393 450.033L406.312 391.871H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_35" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 530H413.031L447.547 599.033L413.031 668.065H321.513L287 598.891L321.519 530ZM328.232 540.871L299.154 598.902L328.238 657.194H406.312L435.393 599.033L406.312 540.871H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_36" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 232H413.031L447.547 301.033L413.031 370.065H321.513L287 300.891L321.519 232ZM328.232 242.871L299.154 300.902L328.238 359.194H406.312L435.393 301.033L406.312 242.871H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector_37" d="M280 896.74L317.37 822H417.114L454.484 896.74L417.114 971.48H317.37L280 896.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_37" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 817H419.794L459.664 896.74L419.794 976.48H313.87L274 896.74L313.87 817ZM320.05 827L285.18 896.74L320.05 966.48H413.614L448.484 896.74L413.614 827H320.05Z"
        fill="#D4A168" />
    <path id="Vector_38" d="M416 79.74L453.37 5H553.114L590.484 79.74L553.114 154.48H453.37L416 79.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_38" fill-rule="evenodd" clip-rule="evenodd"
        d="M449.87 0H555.794L595.664 79.74L555.794 159.48H449.87L410 79.74L449.87 0ZM456.05 10L421.18 79.74L456.05 149.48H549.614L584.484 79.74L549.614 10H456.05Z"
        fill="#D4A168" />
    <path id="Vector_39" d="M280 747.74L317.37 673H417.114L454.484 747.74L417.114 822.48H317.37L280 747.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_39" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 668H419.794L459.664 747.74L419.794 827.48H313.87L274 747.74L313.87 668ZM320.05 678L285.18 747.74L320.05 817.48H413.614L448.484 747.74L413.614 678H320.05Z"
        fill="#D4A168" />
</template>
<template>
    <path id="Vector (Stroke)_51" fill-rule="evenodd" clip-rule="evenodd"
        d="M457.519 755H549.031L583.547 824.033L549.031 893.065H457.513L423 823.891L457.519 755ZM464.232 765.871L435.154 823.902L464.238 882.194H542.312L571.393 824.033L542.312 765.871H464.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_52" fill-rule="evenodd" clip-rule="evenodd"
        d="M457.519 606H549.031L583.547 675.033L549.031 744.065H457.513L423 674.891L457.519 606ZM464.232 616.871L435.154 674.902L464.238 733.194H542.312L571.393 675.033L542.312 616.871H464.232Z"
        fill="#E8D9AA" />
    <path id="Vector_53" d="M280 1045.74L317.37 971H417.114L454.484 1045.74L417.114 1120.48H317.37L280 1045.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_53" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 966H419.794L459.664 1045.74L419.794 1125.48H313.87L274 1045.74L313.87 966ZM320.05 976L285.18 1045.74L320.05 1115.48H413.614L448.484 1045.74L413.614 976H320.05Z"
        fill="#D4A168" />
</template>
<template>
    <path id="Vector (Stroke)_46" fill-rule="evenodd" clip-rule="evenodd"
        d="M457.519 159H549.031L583.547 228.033L549.031 297.065H457.513L423 227.891L457.519 159ZM464.232 169.871L435.154 227.902L464.238 286.194H542.312L571.393 228.033L542.312 169.871H464.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_47" fill-rule="evenodd" clip-rule="evenodd"
        d="M457.519 308H549.031L583.547 377.033L549.031 446.065H457.513L423 376.891L457.519 308ZM464.232 318.871L435.154 376.902L464.238 435.194H542.312L571.393 377.033L542.312 318.871H464.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_48" fill-rule="evenodd" clip-rule="evenodd"
        d="M457.519 457H549.031L583.547 526.033L549.031 595.065H457.513L423 525.891L457.519 457ZM464.232 467.871L435.154 525.902L464.238 584.194H542.312L571.393 526.033L542.312 467.871H464.232Z"
        fill="#E8D9AA" />
    <path id="Vector_49" d="M416 823.74L453.37 749H553.114L590.484 823.74L553.114 898.48H453.37L416 823.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_49" fill-rule="evenodd" clip-rule="evenodd"
        d="M449.87 744H555.794L595.664 823.74L555.794 903.48H449.87L410 823.74L449.87 744ZM456.05 754L421.18 823.74L456.05 893.48H549.614L584.484 823.74L549.614 754H456.05Z"
        fill="#D4A168" />
    <path id="Vector_50" d="M416 674.74L453.37 600H553.114L590.484 674.74L553.114 749.48H453.37L416 674.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_50" fill-rule="evenodd" clip-rule="evenodd"
        d="M449.87 595H555.794L595.664 674.74L555.794 754.48H449.87L410 674.74L449.87 595ZM456.05 605L421.18 674.74L456.05 744.48H549.614L584.484 674.74L549.614 605H456.05Z"
        fill="#D4A168" />
</template>
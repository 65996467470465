<template>
    <path id="Vector (Stroke)_30" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 82H413.031L447.547 151.033L413.031 220.065H321.513L287 150.891L321.519 82ZM328.232 92.8713L299.154 150.902L328.238 209.194H406.312L435.393 151.033L406.312 92.8713H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector_31" d="M280 300.74L317.37 226H417.114L454.484 300.74L417.114 375.48H317.37L280 300.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_31" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 221H419.794L459.664 300.74L419.794 380.48H313.87L274 300.74L313.87 221ZM320.05 231L285.18 300.74L320.05 370.48H413.614L448.484 300.74L413.614 231H320.05Z"
        fill="#D4A168" />
    <path id="Vector_32" d="M280 449.74L317.37 375H417.114L454.484 449.74L417.114 524.48H317.37L280 449.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_32" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 370H419.794L459.664 449.74L419.794 529.48H313.87L274 449.74L313.87 370ZM320.05 380L285.18 449.74L320.05 519.48H413.614L448.484 449.74L413.614 380H320.05Z"
        fill="#D4A168" />
    <path id="Vector_33" d="M280 598.74L317.37 524H417.114L454.484 598.74L417.114 673.48H317.37L280 598.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_33" fill-rule="evenodd" clip-rule="evenodd"
        d="M313.87 519H419.794L459.664 598.74L419.794 678.48H313.87L274 598.74L313.87 519ZM320.05 529L285.18 598.74L320.05 668.48H413.614L448.484 598.74L413.614 529H320.05Z"
        fill="#D4A168" />
</template>
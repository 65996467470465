<template>
    <path id="Vector (Stroke)_17" fill-rule="evenodd" clip-rule="evenodd"
        d="M184.519 11H276.031L310.547 80.0326L276.031 149.065H184.513L150 79.8915L184.519 11ZM191.232 21.8713L162.154 79.9018L191.238 138.194H269.312L298.393 80.0325L269.312 21.8713H191.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_18" fill-rule="evenodd" clip-rule="evenodd"
        d="M184.519 160H276.031L310.547 229.033L276.031 298.065H184.513L150 228.891L184.519 160ZM191.232 170.871L162.154 228.902L191.238 287.194H269.312L298.393 229.033L269.312 170.871H191.232Z"
        fill="#E8D9AA" />
    <path id="Vector_19" d="M143 377.74L180.37 303H280.114L317.484 377.74L280.114 452.48H180.37L143 377.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_19" fill-rule="evenodd" clip-rule="evenodd"
        d="M176.87 298H282.794L322.664 377.74L282.794 457.48H176.87L137 377.74L176.87 298ZM183.05 308L148.18 377.74L183.05 447.48H276.614L311.484 377.74L276.614 308H183.05Z"
        fill="#D4A168" />
    <path id="Vector_20" d="M143 526.74L180.37 452H280.114L317.484 526.74L280.114 601.48H180.37L143 526.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_20" fill-rule="evenodd" clip-rule="evenodd"
        d="M176.87 447H282.794L322.664 526.74L282.794 606.48H176.87L137 526.74L176.87 447ZM183.05 457L148.18 526.74L183.05 596.48H276.614L311.484 526.74L276.614 457H183.05Z"
        fill="#D4A168" />
    <path id="Vector_21" d="M143 675.74L180.37 601H280.114L317.484 675.74L280.114 750.48H180.37L143 675.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_21" fill-rule="evenodd" clip-rule="evenodd"
        d="M176.87 596H282.794L322.664 675.74L282.794 755.48H176.87L137 675.74L176.87 596ZM183.05 606L148.18 675.74L183.05 745.48H276.614L311.484 675.74L276.614 606H183.05Z"
        fill="#D4A168" />
</template>
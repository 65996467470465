<template>
    <path id="Vector (Stroke)_22" fill-rule="evenodd" clip-rule="evenodd"
        d="M184.519 309H276.031L310.547 378.033L276.031 447.065H184.513L150 377.891L184.519 309ZM191.232 319.871L162.154 377.902L191.238 436.194H269.312L298.393 378.033L269.312 319.871H191.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_23" fill-rule="evenodd" clip-rule="evenodd"
        d="M184.519 458H276.031L310.547 527.033L276.031 596.065H184.513L150 526.891L184.519 458ZM191.232 468.871L162.154 526.902L191.238 585.194H269.312L298.393 527.033L269.312 468.871H191.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_24" fill-rule="evenodd" clip-rule="evenodd"
        d="M184.519 607H276.031L310.547 676.033L276.031 745.065H184.513L150 675.891L184.519 607ZM191.232 617.871L162.154 675.902L191.238 734.194H269.312L298.393 676.033L269.312 617.871H191.232Z"
        fill="#E8D9AA" />
    <path id="Vector_25" d="M143 973.74L180.37 899H280.114L317.484 973.74L280.114 1048.48H180.37L143 973.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_25" fill-rule="evenodd" clip-rule="evenodd"
        d="M176.87 894H282.794L322.664 973.74L282.794 1053.48H176.87L137 973.74L176.87 894ZM183.05 904L148.18 973.74L183.05 1043.48H276.614L311.484 973.74L276.614 904H183.05Z"
        fill="#D4A168" />
    <path id="Vector_26" d="M143 824.74L180.37 750H280.114L317.484 824.74L280.114 899.48H180.37L143 824.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_26" fill-rule="evenodd" clip-rule="evenodd"
        d="M176.87 745H282.794L322.664 824.74L282.794 904.48H176.87L137 824.74L176.87 745ZM183.05 755L148.18 824.74L183.05 894.48H276.614L311.484 824.74L276.614 755H183.05Z"
        fill="#D4A168" />
</template>
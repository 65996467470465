<template>
	<path id="Vector (Stroke)_4" fill-rule="evenodd" clip-rule="evenodd"
		d="M47.5194 82H139.031L173.547 151.033L139.031 220.065H47.513L13 150.891L47.5194 82ZM54.2318 92.8713L25.1544 150.902L54.2383 209.194H132.312L161.393 151.033L132.312 92.8713H54.2318Z"
		fill="#E8D9AA" />
	<path id="Vector (Stroke)_5" fill-rule="evenodd" clip-rule="evenodd"
		d="M47.5194 231H139.031L173.547 300.033L139.031 369.065H47.513L13 299.891L47.5194 231ZM54.2318 241.871L25.1544 299.902L54.2383 358.194H132.312L161.393 300.033L132.312 241.871H54.2318Z"
		fill="#E8D9AA" />
	<path id="Vector (Stroke)_6" fill-rule="evenodd" clip-rule="evenodd"
		d="M47.5194 380H139.031L173.547 449.033L139.031 518.065H47.513L13 448.891L47.5194 380ZM54.2318 390.871L25.1544 448.902L54.2383 507.194H132.312L161.393 449.033L132.312 390.871H54.2318Z"
		fill="#E8D9AA" />
	<path id="Vector_7" d="M6 597.74L43.3699 523H143.114L180.484 597.74L143.114 672.48H43.3699L6 597.74Z" fill="#D4A168" />
	<path id="Vector (Stroke)_7" fill-rule="evenodd" clip-rule="evenodd"
		d="M39.8699 518H145.794L185.664 597.74L145.794 677.48H39.8699L0 597.74L39.8699 518ZM46.0503 528L11.1803 597.74L46.0503 667.48H139.614L174.484 597.74L139.614 528H46.0503Z"
		fill="#D4A168" />
	<path id="Vector_8" d="M6 746.74L43.3699 672H143.114L180.484 746.74L143.114 821.48H43.3699L6 746.74Z" fill="#D4A168" />
	<path id="Vector (Stroke)_8" fill-rule="evenodd" clip-rule="evenodd"
		d="M39.8699 667H145.794L185.664 746.74L145.794 826.48H39.8699L0 746.74L39.8699 667ZM46.0503 677L11.1803 746.74L46.0503 816.48H139.614L174.484 746.74L139.614 677H46.0503Z"
		fill="#D4A168" />
	<path id="Vector_9" d="M6 895.74L43.3699 821H143.114L180.484 895.74L143.114 970.48H43.3699L6 895.74Z" fill="#D4A168" />
	<path id="Vector (Stroke)_9" fill-rule="evenodd" clip-rule="evenodd"
		d="M39.8699 816H145.794L185.664 895.74L145.794 975.48H39.8699L0 895.74L39.8699 816ZM46.0503 826L11.1803 895.74L46.0503 965.48H139.614L174.484 895.74L139.614 826H46.0503Z"
		fill="#D4A168" />
</template>
<template>
    <path id="Vector (Stroke)_40" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 828H413.031L447.547 897.033L413.031 966.065H321.513L287 896.891L321.519 828ZM328.232 838.871L299.154 896.902L328.238 955.194H406.312L435.393 897.033L406.312 838.871H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_41" fill-rule="evenodd" clip-rule="evenodd"
        d="M457.519 11H549.031L583.547 80.0326L549.031 149.065H457.513L423 79.8915L457.519 11ZM464.232 21.8713L435.154 79.9018L464.238 138.194H542.312L571.393 80.0325L542.312 21.8713H464.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_42" fill-rule="evenodd" clip-rule="evenodd"
        d="M321.519 679H413.031L447.547 748.033L413.031 817.065H321.513L287 747.891L321.519 679ZM328.232 689.871L299.154 747.902L328.238 806.194H406.312L435.393 748.033L406.312 689.871H328.232Z"
        fill="#E8D9AA" />
    <path id="Vector_43" d="M416 227.74L453.37 153H553.114L590.484 227.74L553.114 302.48H453.37L416 227.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_43" fill-rule="evenodd" clip-rule="evenodd"
        d="M449.87 148H555.794L595.664 227.74L555.794 307.48H449.87L410 227.74L449.87 148ZM456.05 158L421.18 227.74L456.05 297.48H549.614L584.484 227.74L549.614 158H456.05Z"
        fill="#D4A168" />
    <path id="Vector_44" d="M416 376.74L453.37 302H553.114L590.484 376.74L553.114 451.48H453.37L416 376.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_44" fill-rule="evenodd" clip-rule="evenodd"
        d="M449.87 297H555.794L595.664 376.74L555.794 456.48H449.87L410 376.74L449.87 297ZM456.05 307L421.18 376.74L456.05 446.48H549.614L584.484 376.74L549.614 307H456.05Z"
        fill="#D4A168" />
    <path id="Vector_45" d="M416 525.74L453.37 451H553.114L590.484 525.74L553.114 600.48H453.37L416 525.74Z"
        fill="#D4A168" />
    <path id="Vector (Stroke)_45" fill-rule="evenodd" clip-rule="evenodd"
        d="M449.87 446H555.794L595.664 525.74L555.794 605.48H449.87L410 525.74L449.87 446ZM456.05 456L421.18 525.74L456.05 595.48H549.614L584.484 525.74L549.614 456H456.05Z"
        fill="#D4A168" />
</template>